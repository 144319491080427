import * as React from 'react';
import styles from './Content.module.scss';
import image from '../../Images/surgical-mask.jpg';
import image2 from '../../Images/surgical-50-pack2.jpg';
import canadaLogo from '../../Images/Canada_Maple_Leaf.svg';
import madeInOntario from '../../Images/Made_in_Ontario_logo_bilingual.webp';

import PageSection from './PageSection';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IProductsProps {
}

export interface IProductsState {

}


export default class Products extends React.Component<IProductsProps, IProductsState> {
  constructor(props: Readonly<IProductsProps>) {
    super(props);

  }

  public getContent = () => {
    return (
      <div>
        <h1>
          What we produce
          <img src={madeInOntario} alt="Made In Ontario" style={{ height: "32px", margin: "10px" }} />
        </h1>
        <p>We take pride in our work. Our masks are produce to the highest standards and we stand behind each <strong>Canadian</strong> made product!</p>
        <p>What we offer:</p>
        <ul>
          <li>
            3 Ply Surgical face masks made of non-woven material
          </li>
          <li>
            High filtering ability, extremely low resistance, easy to breathe
          </li>
          <li>
            Are glue-free and odorless
          </li>
          <li>
            Soft Lined, water resistant
          </li>
          <li>
            Standard Earloops
          </li>
          <li>
            Filters dust, small particles, mist and other pollutants
          </li>
          <li>
            Soft and flexible nose bridge, can be adjusted to the face
          </li>
          <li>
            Helps prevent the spread diseases and infections
          </li>
        </ul>
        <h1>What is a non-woven mask?</h1>
        <p>Non-woven masks are made from polypropylene, created using a melt-blown process.</p>
        <p>Face masks made from non-woven fabric are more effective at blocking the spread of Covid-19 via airborne respiratory droplets than other types that are commonly available, according to modelling in Japan by the world’s fastest supercomputer.</p>
        <p>Woven masks are typically made from fabrics such as cotton, and appeared in some countries after non-woven versions were temporarily in short supply.</p>
        <p>They can be reused and generally offer more breathability but, according to the World Health Organization (WHO), should be washed in soap or detergent and water of at least 60C at least once a day.</p>
      </div>
    );
  }

  public render = () => {
    return (
      <>
        <PageSection
          titleUpper={strings.CanadianMade}
          titleLower={strings.ProductDescription}
          // description="We take pride in our work. Our masks are produce to the highest standards and we stand behind each Canadian made product!"
          content={this.getContent()}
          imageUrl={image}
          imageAlt="Image Alt"
          topSectionLeft={true}
          bottomSectionLeft={false} />
        <PageSection
          titleUpper=""
          titleLower={"50 Pack 3-ply Disposable Mask"}
          content={(
            <div>
              <p>General purpose mask for civilian or professional use.
              Disposable masks are manufactured in Ontario from high quality, north american sourced materials, that can be worn up to 8 hours at a time.
              Features 3 layers of protection. Lightweight, soft and comfortable to wear.
              Works with any face shape with easy to adjust elastic straps.
                Box of 50 pieces each. In stock, ready to ship.</p>
              <p>
                <h3>Contactless Curbside Pickup</h3>
                Order online and pick up at our warehouse without leaving your car.
                Here’s How It Works:
                <ol>
                  <li>
                    Place your online order with Local Pick Up.
                  </li>
                  <li>
                    Wait for an email confirmation that your order is ready for curbside pickup.</li>
                  <li>
                    Upon arrival, have your ID and confirmation ready. Call the store and an associate will bring out your order. (The phone number is on order confirmation email.)
                  </li>
                </ol>
                <p>Please contact us by email at <a href="mailto:sales@copihue.ca">sales@copihue.ca</a> for pickup appointments.</p>
              </p>
            </div>
          )}
          imageUrl={image2}
          imageAlt="50 pack"
          topSectionLeft={false}
          bottomSectionLeft={true}
        />
      </>
    );
  }
}