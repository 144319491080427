import * as React from 'react';
import styles from './Content.module.scss';
import image from '../../Images/Mask.png';
import { JsxElement } from 'typescript';

export interface IPageSectionProps {
    titleUpper: string;
    titleLower: string;
    titleImageUrl?: string;
    imageUrl: string;
    imageAlt: string;
    description?: string;
    content?: any;
    topSectionLeft: boolean;
    bottomSectionLeft: boolean;
}

export interface IPageSectionState {

}


export default class PageSection extends React.Component<IPageSectionProps, IPageSectionState> {
    constructor(props: Readonly<IPageSectionProps>) {
        super(props);

    }

    public render = () => {
        return (
            <div>
                <section className="page-section">
                    <div className="container">
                        <div className="product-item">
                            <div className="product-item-title d-flex">
                                <div className={"bg-faded p-5 d-flex " + (this.props.topSectionLeft ? "mr-auto" : "ml-auto") + " rounded"}>
                                    <h2 className="section-heading mb-0">
                                        {this.props.titleImageUrl && (<img src={this.props.titleImageUrl} alt="Title Image" className="title-image-right" />)}
                                        <span className="section-heading-upper">{this.props.titleUpper}</span>
                                        <span className="section-heading-lower">{this.props.titleLower}</span>
                                    </h2>
                                </div>
                            </div>
                            <img className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src={this.props.imageUrl} alt={this.props.imageUrl} />
                            <div className={"product-item-description d-flex " + (this.props.bottomSectionLeft ? "mr-auto" : "ml-auto") }>
                                <div className="bg-faded p-5 rounded">
                                    {this.props.description && <p className="mb-0">{this.props.description}</p>}
                                    {this.props.content && (this.props.content)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}