import * as React from 'react';
import styles from './Content.module.scss';

export interface IPlainContentProps {
    title: string;
    body: React.ReactElement;
}

export default class PlainContent extends React.Component<IPlainContentProps, {}> {
    constructor(props: IPlainContentProps) {
        super(props);
    }

    public render = () => {
        return (
            <section className="page-section clearfix">
                <div className="container">
                    <div className={styles.PlainContent + " rounded bg-faded"}>
                        {this.props.title && (
                        <h2 className={styles.Heading}>
                            {this.props.title}
                        </h2>)
                        }
                        {this.props.body && (
                        <div className={styles.Body}>{this.props.body}</div>)
                        }
                    </div>
                </div>
            </section>
        )
    }
}