export interface IEmailFormData {
    name: string;
    email: string;
    my_email: string;
    phone: string;
    address?: string;
    city?: string;
    postalCode?: string;
    message: string;
    sendCopy: boolean;
}

// const emailApiUrl = "https://www.copihue.ca/sendMail.php"; // Try to get this to work during development
const emailApiUrl = "/sendMail.php";

export default class EmailHelper {
    public static SendEmail = (formData: IEmailFormData): Promise<any> => {
        // If we are hitting remote server, we will get a CORS error. 
        const p: Promise<any> = new Promise((resolve, reject) => {
            console.log(JSON.stringify(formData));
            const options = {
                "method": "POST",
                "body": JSON.stringify(formData),
                "headers": new Headers({
                    "Content-Type": "application/json"
                })
            }

            fetch(emailApiUrl, options)
                .then(response => response.text())
                .then(response => {
                    resolve(response);
                    console.log(response)
                })
                .catch(err => {
                    console.log(err);
                    // This commented section is for test with email when running locally
                    // if(err.message === "Failed to fetch") {
                    //     // Let this go through for testing
                    //     resolve("Failed to fetch");
                    // } else {
                        reject(err);
                    // }
                });

        })
        return p;
    }
}