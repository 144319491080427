import * as React from 'react';
import imgLinkedIn from '../../Images/Social/LinkedIn.svg';
import imgYouTube from '../../Images/Social/YouTube.svg';
import imgTwitter from '../../Images/Social/Twitter.svg';
import imgFacebook from '../../Images/Social/Facebook.svg';
import imgInstagram from '../../Images/Social/Instagram.svg';
import styles from './SocialLink.module.scss';
import FacebookGlyph from '../../Glyphs/FacebookGlyph';
import TwitterGlyph from '../../Glyphs/TwitterGlyph';
import InstagramGlyph from '../../Glyphs/InstagramGlyph';
import LinkedInGlyph from '../../Glyphs/LinkedInGlyph';
import YoutubeGlyph from '../../Glyphs/YoutubeGlyph';

export enum SocialType {
    LinkedIn,
    Twitter,
    Facebook,
    YouTube,
    Instagram
}

export interface ISocialLinkProps {
    type: SocialType;
    url: string;
    height: number;
}

export default class SocialLink extends React.Component<ISocialLinkProps, {}> {

    constructor(props: ISocialLinkProps) {
        super(props);
    }

    public getImage = (type: SocialType): React.ReactElement => {
        // let image: React.ReactElement;
        if (type == SocialType.LinkedIn) {
            return <LinkedInGlyph height={this.props.height} />
        } else if (type == SocialType.Twitter) {
            return <TwitterGlyph height={this.props.height} />
        } else if (type == SocialType.Facebook) {
            return <FacebookGlyph height={this.props.height} />
        } else if (type == SocialType.YouTube) {
            return <YoutubeGlyph height={this.props.height} />
        } else { //if(type == SocialType.Instagram) 
            return <InstagramGlyph height={this.props.height} />
        }
        // return image;
    }

    public render = () => {
        return (
            <a className={styles.SocialLink} href={this.props.url} target="_blank">
                {this.getImage(this.props.type)}
            </a>
        );
    }
}