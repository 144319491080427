import * as React from 'react';
import styles from './Content.module.scss';
import SocialLink, { SocialType } from '../SocialLink/SocialLink';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface ISocialMediaProps {
    height?: number;
}

export interface ISocialMediaState {

}

export default class SocialMedia extends React.Component<ISocialMediaProps, ISocialMediaState> {
    private height: number = this.props.height || 41;

    constructor(props: Readonly<ISocialMediaProps>) {
        super(props);

    }

    public getSocialMedia = () => {
        return (
            <>
                {strings.UrlFacebook && <SocialLink 
                    type={SocialType.Facebook}
                    url={strings.UrlFacebook}
                    height={this.height}
                />}
                {strings.UrlTwitter && <SocialLink
                    type={SocialType.Twitter}
                    url={strings.UrlTwitter}
                    height={this.height}
                />}
                {strings.UrlLinkedIn && <SocialLink
                    type={SocialType.LinkedIn}
                    url={strings.UrlLinkedIn}
                    height={this.height}
                />}
                {strings.UrlYoutube && <SocialLink
                    type={SocialType.YouTube}
                    url={strings.UrlYoutube}
                    height={this.height}
                />}
                {strings.UrlInstagram && <SocialLink
                    type={SocialType.Instagram}
                    url={strings.UrlInstagram}
                    height={this.height}
                />}

            </>
        );
    }

    public render = () => {
        return (
            <>
                {this.getSocialMedia()}
            </>
        );
    }
}