import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import styles from '../ContentArea/Content.module.scss';
import PlainContent from '../ContentArea/PlainContent';
import { Link } from 'react-router-dom';
import ShoppingCartItem from './ShoppingCartItem';
import Product1Image from '../../Images/surgical-mask.jpg';
const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IShoppingCartProps {
    // action: string;
}

export interface IShoppingCartState {
    submitSuccess?: boolean;
}

export default class ShoppingCart extends React.Component<IShoppingCartProps, IShoppingCartState> {
    constructor(props: Readonly<IShoppingCartProps>) {
        super(props);

        this.state = {
        };
    }

    /**
   * Submits the form to the http api
   * @returns {boolean} - Whether the form submission was successful or not
   */
    private submitForm = (): Promise<boolean> => {
        // TODO - submit the form
        const p: Promise<boolean> = new Promise((resolve) => {
            resolve(true);
        });
        return p;
    }


    public render = () => {
        return (

            <section className="page-section clearfix">
                <div className="container">
                    <div className={styles.PlainContent + " rounded bg-faded"}>
                        <h2 className={styles.Heading}>
                            {strings.ShoppingCartTitle}
                        </h2>
                        <br />
                        {!this.state.submitSuccess && (
                            <div className={styles.InputForm}>
                                <div className="row">
                                    <div className="col col-12">
                                        <ShoppingCartItem
                                            image={Product1Image}
                                            title="Surgical Mask"
                                            description="3-ply surgical masks, packs of 50"
                                            quantity={10}
                                        />
                                    </div>
                                </div>
                                <div className="row"><div className="col col-12">&nbsp;</div></div>
                                <div className="row">
                                    <div className="col col-12">
                                        <button type="submit" className={styles.InputControl + " btn btn-primary btn-form float-right"} onClick={this.submitForm}>{strings.ShoppingCartSubmit}</button>
                                    </div>
                                </div>
                            </div>)}
                        {this.state.submitSuccess && (
                            <div className={styles.SubmitSuccess}>
                                <PlainContent title="" body={(
                                    <div className={styles.SuccessResponse}>
                                        <p>Thank you for your submission. Someone will respond quickly.</p>
                                        <p></p>
                                        <p><Link className={"text-expanded nav-link "} to={"/"} >{"Navigate back to site"}</Link></p>
                                    </div>
                                )} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}