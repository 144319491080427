import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const InstagramGlyph = ({ height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Instagram"
            version="1.1"
            viewBox="0 0 10.847916 10.847917"
            height={height}
            className={styles.InstagramGlyph}>
                <title>Instagram</title>
            <g>
                <path
                    className={styles.InstagramPrimary}
                    d="M 4.9427495,10.834066 C 2.9358988,10.68587 1.09527,9.3010351 0.38115733,7.4231119 -0.36832902,5.5873628 0.00551694,3.3439212 1.3324819,1.8677182 2.4297588,0.56824382 4.1708867,-0.13497743 5.8644796,0.01474471 7.8668134,0.14862522 9.7209802,1.5091425 10.456342,3.3761867 11.004992,4.7279539 10.984046,6.3132459 10.38529,7.6441464 9.5629143,9.5397079 7.5726973,10.867086 5.5009093,10.847727 5.3150674,10.84582 5.12783,10.852736 4.9427495,10.834066 Z M 7.2283906,8.9944169 C 8.2833875,8.8631939 9.1060831,7.8348961 9.0120297,6.7773289 9.0107812,5.7208509 9.0235601,4.663586 8.9931,3.6075944 8.8510864,2.5996179 7.8842257,1.777888 6.8594324,1.851428 5.7481231,1.8488595 4.6323237,1.8102513 3.5238735,1.881984 2.4988713,2.0661392 1.735399,3.1000964 1.8500173,4.134655 c 0.0048,1.0442489 -0.00762,2.0916851 0.01287,3.1345428 0.1666774,1.0401649 1.1827863,1.8449816 2.2350477,1.7399801 1.0431791,-3.133e-4 2.0883192,0.02839 3.1304556,-0.014761 z M 3.6166828,8.4252559 C 2.8708225,8.2851961 2.3244578,7.5339722 2.4097523,6.7811007 2.4127209,5.7294919 2.3985201,4.6730084 2.4225333,3.6235394 2.5728492,2.9053232 3.2819245,2.3244781 4.0228704,2.4107139 5.1078424,2.421801 6.1954068,2.3826652 7.2783344,2.4382839 8.0359926,2.6014623 8.552074,3.380735 8.4488628,4.1407306 8.4481155,5.1450602 8.4722873,6.1502038 8.4416015,7.1540359 8.3433874,7.9467486 7.5619804,8.5389749 6.7743836,8.4539477 5.7225803,8.4451411 4.6658782,8.4896097 3.6166828,8.4252559 Z M 5.7197122,7.3392209 C 6.8304783,7.1996949 7.6265357,5.9721671 7.286176,4.9018198 7.0279707,3.9137772 5.9111545,3.2749541 4.9287675,3.5636424 3.8000013,3.8274486 3.1526693,5.2225743 3.686256,6.2523076 4.0368602,7.0144254 4.8908062,7.4688993 5.7197122,7.3392209 Z M 5.1444952,6.7747669 C 4.3599796,6.6307626 3.8684585,5.7408169 4.125661,4.9958579 4.3608748,4.2131932 5.3463664,3.8204175 6.0595931,4.2088535 6.8692189,4.5860324 7.0542018,5.7952367 6.400662,6.4001289 6.0798714,6.7434842 5.595745,6.8481351 5.1444952,6.7747669 Z" />
                <path
                    className={styles.InstagramSecondary}
                    d="M 7.9052208,3.3555782 A 0.41552812,0.41552812 0 0 1 7.4896927,3.7711063 0.41552812,0.41552812 0 0 1 7.0741646,3.3555782 0.41552812,0.41552812 0 0 1 7.4896927,2.9400501 0.41552812,0.41552812 0 0 1 7.9052208,3.3555782" />
            </g>
        </svg>
    );
}

export default InstagramGlyph;