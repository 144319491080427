import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import styles from './ShoppingCart.module.scss';
const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IShoppingCartItemProps {
    image?: string;
    title: string;
    description: string;
    quantity: number;
}

export interface IShoppingCartItemState {
    selected: boolean;
}

export default class ShoppingCartItem extends React.Component<IShoppingCartItemProps, IShoppingCartItemState> {
    constructor(props: Readonly<IShoppingCartItemProps>) {
        super(props);

        this.state = {
            selected: false
        };
    }

    public render = () => {
        return (
            <div className={styles.ShoppingCartItem}>
                <div className="row">
                    {this.props.image && <div className={styles.ShoppingCartImage + " col col-4"}><img src={this.props.image} alt={this.props.title} /></div>}
                    <div className="col col-8"><p>{this.props.title}</p><p>{this.props.description}</p><p>{`Quantity ${this.props.quantity}`}</p></div>
                </div>
            </div>
        );
    }
}