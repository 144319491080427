import avatarLuis from "../../Images/Luis-Avatar.svg";
import mask from "../../Images/Avatar-mask.svg";
import mask2 from "../../Images/Avatar-mask2.svg";
import avatarAlana from "../../Images/Alana-Avatar.svg";
import avatarSavannah from "../../Images/Savannah-Avatar.svg";
import avatarManny from "../../Images/Alex-Avatar.svg";
import avatarDustin from "../../Images/Dustin-Avatar.svg";

export interface IPeopleDetails {
    Title: string;
    DisplayName: string;
    Initials: string;
    Description: string;
    ImageUrl: string;
    MaskUrl: string;
    Email: string;
    Phone?: string;
}


export class OurPeople {
    public static People: IPeopleDetails[] = [
    {
        Title: "President and CEO",
        DisplayName: "Alana Peterson",
        Initials: "AP",
        Description: "An experienced office manager, Alana has the skills to keep the business team working and the business profitable.",
        ImageUrl: avatarAlana,
        MaskUrl: mask2,
        Email: "alana@copihue.ca"
    },
    {
        Title: "Consultant",
        DisplayName: "Luis Duran",
        Initials: "LD",
        Description: "Luis has a long history of solutions and systems development. He brings these skills to the manufacturing world with hopes of do some great things! ",
        ImageUrl: avatarLuis,
        MaskUrl: mask,
        Email: "luis@copihue.ca",
        Phone: "+1 (289) 470-0655"
    },
    {
        Title: "Sales Manager",
        DisplayName: "Dustin Duran",
        Initials: "DD",
        Description: "Building our sales channel... will be with you shortly!",
        ImageUrl: avatarDustin,
        MaskUrl: mask,
        Email: "dustin@copihue.ca"
    },
    {
        Title: "Social Media Analyst",
        DisplayName: "Savannah Durán",
        Initials: "SD",
        Description: "Contact me at savannah@copihue.ca",
        ImageUrl: avatarSavannah,
        MaskUrl: mask2,
        Email: "savannah@copihue.ca"
    },
    {
        Title: "Development Services Director",
        DisplayName: "Manny Duran",
        Initials: "MD",
        Description: "Development of system to enhance efficiencies.",
        ImageUrl: avatarManny,
        MaskUrl: mask,
        Email: "manny@copihue.ca"
    }]
}
