import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import StickyNav from "react-sticky-nav";
import styles from "./Navigation.module.scss";
//import logo from '../../Images/copihue-logo-colour.svg';
import logo from '../../Images/copihue-logo-colour-with-text.svg';

const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface INavigationProps {
    curNav?: string;
    navigateTo: (desintation: string) => void;
}

export interface INavigationState {
    curNav: string;
    showNav: boolean;    
}

export default class Navigation extends React.Component<INavigationProps, INavigationState> {
    constructor(props: Readonly<INavigationProps>) {
        super(props);
        this.state = {
            curNav: this.props.curNav || "home",
            showNav: false
        };
    }

    public homeClick = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("home");
    }

    public aboutClick = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("about");
    }

    public productsClick = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("products");
    }

    public storeClick = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("store");
    }

    public contactUsClick = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("contactus");
    }

    private navigateTo = (destination: string) => {
        console.log("Navigating to " + destination);
        this.setState({
            curNav: destination,
            showNav: false
        });
        if (this.props.navigateTo) {
            this.props.navigateTo(destination);
        }

    }

    public toggleNavigation = () => {
        this.setState({
            showNav: !this.state.showNav
        });
    }
    
    public render = () => {
        return (
            <StickyNav className={styles.StickyNav}>
                <nav className="navbar navbar-expand-lg navbar-light py-lg-4" id="mainNav">
                    <div className="container">
                        
                        <span className={styles.NavBrand + " navbar-brand text-uppercase text-expanded font-weight-bold d-lg-none"}><img className={styles.NavLogo} src={logo} alt="Copihue" />{strings.Title}</span>
                        <button className={styles.NavBarToggler + " navbar-toggler"} type="button"
                            onClick={this.toggleNavigation}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive" style={{ display: this.state.showNav ? "block" : "none" }}>
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item px-lg-4">
                                    <Link className={"text-uppercase text-expanded nav-link " + (this.state.curNav === "home" ? "active" : "")} to={"/"} onClick={this.homeClick}>{strings.NavHome}</Link>
                                </li>
                                <li className="nav-item px-lg-4">
                                    <Link className={"text-uppercase text-expanded nav-link " + (this.state.curNav === "about" ? "active" : "")} to={"/about"} onClick={this.aboutClick}>{strings.NavAbout}</Link>
                                </li>
                                {/* <li className="nav-item px-lg-4">
                                    <Link className={"text-uppercase text-expanded nav-link " + (this.state.curNav === "products" ? "active" : "")} to={"/products"} onClick={this.productsClick}>{strings.NavProducts}</Link>
                                </li> */}
                                <li className="nav-item px-lg-4">
                                    <Link className={"text-uppercase text-expanded nav-link " + (this.state.curNav === "contactus" ? "active" : "")} to={"/contactus"} onClick={this.contactUsClick}>{strings.NavContactUs}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </StickyNav>
        );
    }
}