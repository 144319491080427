import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import styles from './HeaderFooter.module.scss';
import SocialMedia from '../ContentArea/SocialMedia';
import chatBubble from '../../Images/chat-bubble.svg';

const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IFooterProps {
}

export interface IFooterState {
    chatEnabled: boolean;
    ontario: boolean;
}


export default class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props: Readonly<IFooterProps>) {
        super(props);
        this.state = {
            chatEnabled: false,
            ontario: false
        };
    }

    private chatClicked = () => {
        this.setState((prevState, props) => ({
          chatEnabled: !prevState.chatEnabled  
        }));

        setTimeout(() => {
           this.setState({
               chatEnabled: false
           }); 
        }, 5000);
    }

    private ontarioEnter = () => {
        this.setState({
            ontario: true
          });  
    }

    private ontarioExit = () => {
        this.setState({
            ontario: false
          });
  
    }

    public render = () => {
        return (
            <div className={styles.Footer}>
                <div className={styles.LeftContent}>
                    <span className={styles.CopyrightLine}>{strings.Copyright}</span>
                </div>
                <div className={styles.RightContent}>
                    <SocialMedia height={24} />
                </div>
                <div className={styles.ChatBubble}>
                    <img 
                        src={chatBubble} 
                        className={this.state.chatEnabled ? styles.Large : styles.Small} 
                        alt="Chat"
                        onClick={this.chatClicked} />
                </div>

                {this.state.chatEnabled && (
                <div className={styles.ChatMessage}>
                    <div className={styles.ChatTitle}>
                        Live Chat
                    </div>
                    
                    <div className={styles.ChatBody}>
                    Chat feature coming soon!
                    </div>
                    <div className={styles.ChatInput}>
                        <input type="text" placeholder="Coming soon..." disabled={true}/>    
                        <button type="submit" className={styles.ChatSend + " btn btn-primary btn-form float-right"} disabled={true}>Send</button>
                    </div>
                </div>)}
            </div>
        );
    }
}