import React, { useState } from 'react';
import styles from './App.module.scss';
import Navigation from './Components/Navigation/Navigation';
import Background from './Components/Background/Background';
import Content from './Components/ContentArea/Content';
import Header from './Components/Shared/Header';
import Footer from './Components/Shared/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

function App() {

  const [contentType, setContentType] = useState("home");
  const navigateTo = (destination: string) => {
    setContentType(destination);
  }
  const getContent = (contentType: string) => {
    return (
      <div>
        <Navigation curNav={contentType} navigateTo={navigateTo} />
        <Content contentType={contentType} />
      </div>);
  }
  return (
    <Router>
      <div className={styles.App} >
        <Background />
        <Header navigateTo={navigateTo} />
        <Switch>
          <Route exact={true} path="/">
            {getContent("home")}
          </Route>
          <Route exact={true} path="/home">
            {getContent("home")}
          </Route>
          <Route path="/about">
            {getContent("about")}
          </Route>
          {/* <Route path="/products">
            {getContent("products")}
          </Route> */}
          <Route path="/contactus">
            {getContent("contactus")}
          </Route>
          <Route path="/contactus2">
            {getContent("contactus2")}
          </Route>
          <Route path="/shoppingCart">
            {getContent("shoppingCart")}
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <Footer />
      </div>

    </Router>
  );
}

export default App;
