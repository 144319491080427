import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const SearchGlyph = ({ className }: { [key: string]: any }, { height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Search"
            version="1.1"
            viewBox="0 0 10.847916 10.847917"
            height={height}
            className={className}>
            <title>Search</title>
            <path
                d="M 4.2743323,2.9424806e-7 C 1.9104149,0.01100638 -1.046732e-4,1.9411689 0,4.3182784 -7.9747203e-5,6.7032323 1.9226191,8.6366388 4.2943622,8.6365568 5.3698689,8.5423142 6.1698767,8.3106648 6.936763,7.6867158 l 2.928094,2.9444002 a 0.54608554,0.54117617 0 0 0 0.436974,0.216789 0.54608554,0.54117617 0 0 0 0.546085,-0.541176 0.54608554,0.54117617 0 0 0 -0.177108,-0.3991239 L 7.7042346,6.9250408 c -1.07e-5,-8.14e-5 1.19e-5,-4.497e-4 0,-5.3e-4 C 8.2692546,6.1609002 8.5800632,5.1184845 8.5887247,4.3182784 8.5888043,1.9333247 6.6661056,-8.1845774e-5 4.2943622,2.9424806e-7 c -0.00668,-1.565807006e-5 -0.013353,-1.565807006e-5 -0.02003,0 z M 4.2985791,0.89259641 C 6.1823851,0.89258846 7.7095135,2.4282218 7.7095057,4.3225188 7.7095135,6.2168161 6.1823851,7.7524492 4.2985791,7.7524413 2.4147741,7.7524492 0.88764474,6.2168161 0.88765265,4.3225188 0.88764482,2.4282218 2.4147733,0.89258854 4.2985791,0.89259641 Z"
                className={className}
            />
        </svg>
    );
}

export default SearchGlyph;