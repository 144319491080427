import * as React from 'react';
import styles from './SearchBox.module.scss';
import SearchGlyph from '../../Glyphs/SearchGlyph';


export interface ISearchBoxProps {
    performSearch: (searchText: string) => void;
}

export interface ISearchBoxState {
    searchText?: string;
    searchActive: boolean;
}


export default class SearchBox extends React.Component<ISearchBoxProps, ISearchBoxState> {
    private timeout: any;

    constructor(props: Readonly<ISearchBoxProps>) {
        super(props);
        this.state = {
            searchActive: false
        };
    }

    private mouseEnter = (event: React.MouseEvent<HTMLElement>) => {

        if (this.timeout != null) { clearTimeout(this.timeout); }

        this.timeout = setTimeout( () => {
            this.setState({
                searchActive: true
            });
        }, 500);
    }

    private mouseLeave = () => {
        if (this.timeout != null) { 
            clearTimeout(this.timeout); 
 
            this.timeout = null;
         }
    }

    private search = () => {
        // Search for the text
        this.setState(
            {
                searchActive: false, 
            });
    }

    private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: event.currentTarget.value
        });
    }

    public render = () => {
        return (
            <div className={styles.SearchBoxContainer}>
                {this.state.searchActive && <input className={styles.SearchBox} type="search" value={this.state.searchText} placeholder="Search" onChange={this.handleSearchChange} />}
                <div className={styles.SearchGlyphContainer} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onClick={this.search}>
                    <SearchGlyph className={styles.SearchGlyph} />
                </div>
                {this.state.searchText && <p>{this.state.searchText}</p>}
            </div>
        );
    }
}