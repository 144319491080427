import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import styles from './HeaderFooter.module.scss';
import logo from '../../Images/copihue-logo-colour-with-text.svg';
import logo2 from '../../Images/copihue-logo-colour.svg';
import flag from '../../Images/Flag_of_Canada.svg';
import ShoppingCartGlyph from '../../Glyphs/ShoppingCartGlyph';
import { Link } from 'react-router-dom';
import SearchBox from './SearchBox';

const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IHeaderProps {
    navigateTo: (desintation: string) => void;
}

export interface IHeaderState {
    cartActive: boolean;
    spinningLogo: boolean;
}


export default class Header extends React.Component<IHeaderProps, IHeaderState> {
    private timeout: any;

    constructor(props: Readonly<IHeaderProps>) {
        super(props);
        this.state = {
            cartActive: false,
            spinningLogo: false,
        };
    }

    public shoppingCartClicked = (event: React.MouseEvent<HTMLElement>) => {
        this.navigateTo("ShoppingCart");
    }

    private navigateTo = (destination: string) => {
        console.log("Navigating to " + destination);
        this.setState({
            cartActive: true
        });
        if (this.props.navigateTo) {
            this.props.navigateTo(destination);
        }

    }


    private search = (searchText: string) => {
        // Search for the text
    }

    private logoMouseEnter = (event: React.MouseEvent<HTMLElement>) => {

        this.timeout = setTimeout( () => {
            this.setState({
                spinningLogo: true
            });
        }, 500);
    }

    private logoMouseLeave = () => {
        if (this.timeout != null) { 
            clearTimeout(this.timeout); 
            this.timeout = null;
         }
    }

    private logo2MouseLeave = () => {
        this.setState({
            spinningLogo: false
        });
    }

    private toggleSpinner = () => {
        this.setState((prevState: IHeaderState) => {
            return {spinningLogo: !prevState.spinningLogo}
        });
    }

    public render = () => {
        return (
            <div className={styles.Header}>
                {this.state.spinningLogo && <img className={styles.SpinningLogo} src={logo2} onMouseLeave={this.logo2MouseLeave}  onClick={this.toggleSpinner} />}
                <img className={styles.SiteLogo} src={logo} onMouseEnter={this.logoMouseEnter} onMouseLeave={this.logoMouseLeave} onClick={this.toggleSpinner} />
                <h1 className="site-heading text-center text-black d-none d-lg-block">
                    <span className="site-heading-lower">{strings.Title}</span>
                </h1>
                {false && <SearchBox performSearch={this.search} />}

                {false && <Link className={"text-expanded nav-link "} to={"/ShoppingCart"} onClick={this.shoppingCartClicked}><div className={styles.ShoppingCartContainer}>
                    <ShoppingCartGlyph className={styles.ShoppingCart} />
                </div>
                </Link>}

                <img className={styles.SiteLogoRight} src={flag} />
            </div>
        );
    }
}