import * as React from 'react';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import styles from './Content.module.scss';
import email from '../../Images/email.svg';
import EmailHelper, { IEmailFormData } from '../../Utilities/EmailHelper';
import PlainContent from './PlainContent';
import { Link } from 'react-router-dom';
const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IContactUsProps {
    // action: string;
}

export interface IContactUsState {
    values: IValues;
    errors: IErrors;
    submitSuccess?: boolean;
}

export interface IValues {
    [key: string]: any;
}

export interface IErrors {
    [key: string]: string;
}

export interface IContactUsContext extends IContactUsState {
    setValues: (values: IValues) => void;
}

export const ContactUsContext = React.createContext<IContactUsContext | undefined>(undefined);

export default class ContactUs extends React.Component<IContactUsProps, IContactUsState> {
    constructor(props: Readonly<IContactUsProps>) {
        super(props);

        const errors: IErrors = {};
        const values: IValues = { Name: "", Phone: "", Email: "", Message: "" };
        this.state = {
            errors,
            values
        };
    }

    /**
   * Returns whether there are any errors in the errors object that is passed in
   * @param {IErrors} errors - The field errors
   */
    private haveErrors(errors: IErrors) {
        let haveError: boolean = false;
        Object.keys(errors).map((key: string) => {
            if (errors[key].length > 0) {
                haveError = true;
            }
        });
        return haveError;
    }

    private removeError(curErrors: IErrors, remove: string) {
        let errors: IErrors = {};

        Object.keys(curErrors).map((key: string) => {
            if (key !== remove) {
                errors[key] = curErrors[key];
            }
        });
        this.setState({
            errors,
            submitSuccess: undefined
        });
    }

    /**
   * Executes the validation rules for all the fields on the form and sets the error state
   * @returns {boolean} - Whether the form is valid or not
   */
    private validateForm(): boolean {
        // TODO - validate form
        let errors: IErrors = {};

        // Check each of the values in the state
        if (!this.state.values["Name"]) {
            errors["Name"] = strings.NameRequiredMessage;
        }
        if (!this.state.values["Email"]) {
            errors["Email"] = strings.EmailRequiredMessage;
        }
        if (!this.state.values["Message"]) {
            errors["Message"] = strings.MessageRequiredMessage;
        }
        this.setState({
            errors
        });

        return !this.haveErrors(errors);
    }

    /**
   * Submits the form to the http api
   * @returns {boolean} - Whether the form submission was successful or not
   */
    private submitForm = (): Promise<boolean> => {
        // TODO - submit the form
        const p: Promise<boolean> = new Promise((resolve) => {
            if (this.validateForm()) {
                EmailHelper.SendEmail({
                    email: this.state.values["Email"],
                    name: this.state.values["Name"],
                    phone: this.state.values["Phone"],
                    message: this.state.values["Message"],
                    sendCopy: this.state.values["SendCopy"],
                    my_email: "info@copihue.ca"
                } as IEmailFormData)
                    .then(response => {
                        console.log(response);
                        this.setState({
                            submitSuccess: true
                        });
                        resolve(true);
                    })
                    .catch(error => {
                        console.log("Error : " + error);
                        resolve(false);
                    })

            }
        });
        return p;
    }
    /**
     * Stores new field values in state
     * @param {IValues} values - The new field values
     */
    private setValues = (values: IValues) => {
        this.setState({ values: { ...this.state.values, ...values } });
    };

    private handleNameChange = (event: any) => {
        this.setValues({ Name: event.target.value });
        this.removeError(this.state.errors, "Name");

    }

    private handlePhoneChange = (event: any) => {
        this.setValues({ Phone: event.target.value });
    }

    private handleEmailChange = (event: any) => {
        this.setValues({ Email: event.target.value });
        this.removeError(this.state.errors, "Email");
    }

    private handleMessageChange = (event: any) => {
        this.setValues({ Message: event.target.value });
        this.removeError(this.state.errors, "Message");
    }

    private handleSendCopyChange = (event: any) => {
        debugger;
        this.setValues({ SendCopy: event.target.checked });
    }

    public render = () => {
        const { submitSuccess, errors } = this.state;

        return (

            <section className="page-section clearfix">
                <div className="container">
                    <div className={styles.PlainContent + " rounded bg-faded"}>
                        <h2 className={styles.Heading}>
                            {strings.ContactUsTitle}
                        </h2>
                        <div className="pb-3">
                            <h4 className={styles.ContactUsInfo}>
                                <img className={styles.EmailIcon} src={email} />{strings.ContactUsInfo}
                            </h4>
                        </div>
                        <div className="pb-3">
                            <p className="mbr-text align-left mbr-fonts-style display-7">
                                {strings.Phone}: <a href={"tel:" + strings.ContactPhoneNumber}>{strings.ContactPhoneNumber}</a> <br />
                                {strings.Email}: <a href={"mailto:" + strings.ContactEmail}>{strings.ContactEmail}</a>
                            </p>
                        </div>

                        {!this.state.submitSuccess && (
                            <div className={styles.InputForm}>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <input
                                            type="text"
                                            className={"form-control " + styles.InputControl + (this.state.errors["Name"] ? (" " + styles.IsInvalid) : "") + " input"}
                                            name="name"
                                            placeholder={strings.YourName}
                                            required={true}
                                            value={this.state.values["Name"]}
                                            onChange={this.handleNameChange}
                                        />
                                        {this.state.errors["Name"] && (
                                            <div className="alert alert-danger" role="alert">
                                                {strings.NameRequiredMessage}
                                            </div>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <input
                                            type="text"
                                            className={styles.InputControl + (this.state.errors["Phone"] ? (" " + styles.IsInvalid) : "") + " form-control input"}
                                            name="phone"
                                            placeholder={strings.Phone}
                                            required={true}
                                            value={this.state.values["Phone"]}
                                            onChange={this.handlePhoneChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <input
                                            type="text"
                                            className={styles.InputControl + (this.state.errors["Email"] ? (" " + styles.IsInvalid) : "") + " form-control input"}
                                            name="email"
                                            placeholder={strings.Email}
                                            required={true}
                                            value={this.state.values["Email"]}
                                            onChange={this.handleEmailChange} />
                                        {this.state.errors["Email"] && (
                                            <div className="alert alert-danger" role="alert">
                                                {strings.EmailRequiredMessage}
                                            </div>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <textarea
                                            className={styles.InputControl + (this.state.errors["Message"] ? (" " + styles.IsInvalid) : "") + " form-control input"}
                                            name="message" rows={3}
                                            placeholder={strings.Message}
                                            style={{ resize: "none" }}
                                            value={this.state.values["Message"]}
                                            onChange={this.handleMessageChange} />
                                        {this.state.errors["Message"] && (
                                            <div className="alert alert-danger" role="alert">
                                                {strings.MessageRequiredMessage}
                                            </div>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12 col-md-6">
                                        <label htmlFor="sendCopyCheckbox">Send me a copy</label>
                                        <input 
                                            type="checkbox" 
                                            className={styles.InputCheckbox} 
                                            id="sendCopyCheckbox" 
                                            title="SendCopy" 
                                            value={this.state.values["SendCopy"]}
                                            style={{margin: "5px"}}
                                            onChange={this.handleSendCopyChange}
                                            />
                                    </div>
                                    <div className="col col-sm-12 col-md-6">
                                        <button type="submit" className={styles.InputControl + " btn btn-primary btn-form float-right"} onClick={this.submitForm}>{strings.SendMessage}</button>
                                        {submitSuccess && (
                                            <div className="alert alert-info" role="alert">
                                                {strings.FormSuccessMessage}
                                            </div>
                                        )}
                                        {submitSuccess === false &&
                                            !this.haveErrors(errors) && (
                                                <div className="alert alert-danger" role="alert">
                                                    {strings.FormErrorMessage}
                                                </div>
                                            )}
                                        {submitSuccess === false &&
                                            this.haveErrors(errors) && (
                                                <div className="alert alert-danger" role="alert">
                                                    {strings.FormInvalidMessage}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>)}
                        {this.state.submitSuccess && (
                            <div className={styles.SubmitSuccess}>
                                <PlainContent title="" body={(
                                    <div className={styles.SuccessResponse}>
                                        <p>Thank you for your submission. Someone will respond quickly.</p>
                                        <p></p>
                                        <p><Link className={"text-expanded nav-link "} to={"/"} >{"Navigate back to site"}</Link></p>
                                    </div>
                                )} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}