import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const LinkedInGlyph = ({ height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="LinkedIn"
            version="1.1"
            viewBox="0 0 10.847916 10.847917"
            height={height}
            className={styles.LinkedInGlyph}>
            <title>LinkedIn</title>
            <g
                transform="matrix(0.18740437,0,0,0.18740437,1.0583333,0.38100002)">
                <path
                    className={styles.LinkedInPrimary}
                    d="M 52.237752,26.909501 A 28.942538,28.942538 0 0 1 23.295213,55.85204 28.942538,28.942538 0 0 1 -5.6473246,26.909501 28.942538,28.942538 0 0 1 23.295213,-2.0330368 28.942538,28.942538 0 0 1 52.237752,26.909501" />
                <path
                    className={styles.LinkedInSecondary}
                    d="m 18.757186,21.56014 h 6.55493 v 3.265609 c 0.944918,-1.877775 3.366209,-3.565573 7.003691,-3.565573 6.972429,0 8.627297,3.737553 8.627297,10.595733 V 44.557391 H 33.883948 V 33.417722 c 0,-3.905533 -0.944919,-6.10827 -3.348057,-6.10827 -3.33293,0 -4.71955,2.374717 -4.71955,6.10827 V 44.557391 H 18.757186 Z M 6.6557754,44.257427 H 13.714932 V 21.260176 H 6.6557754 Z M 14.723382,13.761072 c 0,5.99928 -9.0760574,5.99928 -9.0760574,0 0,-5.9992797 9.0760574,-5.9992797 9.0760574,0 z" />
            </g>
        </svg>
    );
}

export default LinkedInGlyph;