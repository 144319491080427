import * as React from 'react';
import image from '../../Images/Talent-Management-as-a-Business-Process-1.png';

import PersonCard from './PersonCard';
import { ApplicationStrings, IApplicationStrings } from '../../Strings/loc/myStrings';
import { OurPeople, IPeopleDetails } from '../Shared/ourPeople';
import styles from './Content.module.scss';

const strings: IApplicationStrings = ApplicationStrings.Strings;

export interface IAboutProps {
}

export interface IAboutState {

}


export default class About extends React.Component<IAboutProps, IAboutState> {
  constructor(props: Readonly<IAboutProps>) {
    super(props);

  }

  public render = () => {
    return (
      <>
      <section className="page-section about-heading">
        <div className="container">
          <img className={styles.IntroImg + " img-fluid mb-3 mb-lg-0 rounded"} src={image} alt="" />
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <div className="bg-faded rounded p-5">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-upper">Made in Canada</span>
                    <span className="section-heading-lower">About Us</span>
                  </h2>
                  <p>Copihue (pronounced koh-pee-weh) was started because we saw a need and has evolved to something quite different.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {OurPeople.People?.map((person: IPeopleDetails) => {
        return (<PersonCard 
          key={person.DisplayName}
          title={person.Title}
          displayName={person.DisplayName}
          initials={person.Initials}
          description={person.Description}
          imageUrl={person.ImageUrl}
          maskUrl={person.MaskUrl}
          email={person.Email}
          phone={person.Phone}
           />)
      })}
      </>
    );
  }
}