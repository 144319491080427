import * as React from 'react';
import styles from './Content.module.scss';
import image from '../../Images/Mask.png';

export interface IPersonCardProps {
    title?: string;
    displayName: string;
    initials?: string;
    imageUrl: string;
    maskUrl?: string;
    description: string;
    email?: string;
    phone?: string;
}

export interface IPersonCardState {
    showMask: boolean;
}


export default class PersonCard extends React.Component<IPersonCardProps, IPersonCardState> {
    constructor(props: Readonly<IPersonCardProps>) {
        super(props);
        this.state = {
            showMask: false
        };

    }

    public render = () => {
        const initials = this.props.initials || this.props.displayName.split(" ").map(x => {return x.substring(0, 1).toUpperCase(); });
        return (
            <div>
                <section className={styles.PersonaCard }>
                    <div className="container" >
                        <div className={styles.Persona}>
                            <div className={styles.PersonaTitle + " d-flex"}>
                                <div className={styles.HeadingContainer + " bg-faded p-5 d-flex mr-auto rounded"}>
                                   <h2 className={styles.PersonaHeading + " mb-0"}>
                                        <span className={styles.DisplayName}>{this.props.displayName}</span>
                                        <span className={styles.Title}>{this.props.title}</span>
                                    </h2>
                                </div>
                            </div>
                            <div className={styles.ImageContainer}>
                                <img className={styles.PersonaImg + " mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"} src={this.props.imageUrl} alt={this.props.displayName} onClick={() => {this.setState({showMask: !this.state.showMask})}} />
                                {this.state.showMask && <img className={styles.MaskImg + " mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"} src={this.props.maskUrl} alt={this.props.displayName} onClick={() => {this.setState({showMask: !this.state.showMask})}} />}
                            </div>
                            <div className={styles.PersonaDescription + " d-flex ml-auto" }>
                                <div className="bg-faded p-5 rounded" style={{width: "100%"}}>
                                    <p className="mb-0">{this.props.description}</p>
                                    {this.props.email && (<>Email: <a href={"mailto:" + this.props.email}>{this.props.email}</a><br/></>)}
                                    {this.props.phone && (<>Phone: <a href={"tel:" + this.props.phone}>{this.props.phone}</a></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}