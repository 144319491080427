import * as React from 'react';
import styles from './Content.module.scss';
import image from '../../Images/Business-Process-Management.png';
import PlainContent from './PlainContent';

import SocialLink, { SocialType } from '../SocialLink/SocialLink';
import SocialMedia from './SocialMedia';

export interface IHomeProps {
}

export interface IHomeState {

}


export default class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: Readonly<IHomeProps>) {
    super(props);

  }

  public getSocialMedia = () => {
    return (
      <div style={{marginTop: "140px", textAlign: "right"}}>
        <SocialMedia/>
     </div>
    );
  }
  
  public render = () => {
    return (
      <>
        <section className="page-section clearfix">
          <div className="container">
            <div className="intro">
              <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={image} alt="" />
              <div className="intro-text left-0 bg-faded p-5 rounded">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-upper">Business Management</span>
                  <span className="section-heading-lower">Business Management Solutions</span>
                </h2>
                <p className="mb-3">Copihue is a Canadian company located in Newmarket, Ontario.
                  </p>
              </div>
            </div>
          </div>
        </section>
        <PlainContent
          title="Follow us on Social Media"
          body={this.getSocialMedia()}
        />        
      </>
    );
  }
}