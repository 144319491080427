import * as React from 'react';
import styles from './Background.module.scss';
import image from '../../Images/bg3.jpg';

export interface IBackgroundProps {

}

export interface IBackgroundState {
    
}


export default class Background extends React.Component<IBackgroundProps, IBackgroundState> {
    constructor(props: Readonly<IBackgroundProps>) {
        super(props);

    }

    public render = () => {
        return (        
            <div className={styles.Background} style={{
            //   backgroundImage: 'url(' + image + ')',
              backgroundSize: "cover",
              backgroundColor: "#5fc8e8",
              height: "100vh",
              color: "#f5f5f5"
            }}>
        </div>
        );
    }
}