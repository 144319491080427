import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const YoutubeGlyph = ({ height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="YouTube"
            version="1.1"
            viewBox="0 0 10.847916 10.847917"
            height={height}
            className={styles.YoutubeGlyph}>
            <title>YouTube</title>
            <g>
                <path
                    d="M 10.847917,5.4239583 A 5.4239583,5.4239583 0 0 1 5.423958,10.847916 5.4239583,5.4239583 0 0 1 0,5.4239583 5.4239583,5.4239583 0 0 1 5.423958,0 5.4239583,5.4239583 0 0 1 10.847917,5.4239583 Z"
                    className={styles.YoutubePrimary} />
                <path
                    className={styles.YoutubeSecondary}
                    d="m 7.3282853,7.5158862 c 0.463107,-0.03093 0.556433,-0.0596 0.695603,-0.213698 0.181703,-0.201187 0.277335,-0.523543 0.311506,-1.050019 0.02191,-0.337658 0.01459,-1.336744 -0.01173,-1.599589 -0.03134,-0.312937 -0.165189,-0.682471 -0.302129,-0.834094 -0.173353,-0.191943 -0.340138,-0.21 -2.092076,-0.226505 -1.305748,-0.0123 -2.337213,0.01226 -2.556209,0.06087 -0.225118,0.04997 -0.324076,0.132617 -0.441075,0.36838 -0.08783,0.17698 -0.132157,0.325898 -0.161283,0.541799 -0.03157,0.233992 -0.03512,0.361372 -0.03227,1.157962 0.0024,0.655768 0.0069,0.742928 0.04917,0.944968 0.09512,0.454456 0.266263,0.723848 0.504204,0.793666 0.101226,0.0297 0.627554,0.06961 1.132649,0.08589 0.395426,0.01274 2.607206,-0.0098 2.903642,-0.02963 z m -2.36309,-0.940189 10e-4,-2.053038 1.587904,1.036731 -1.58898,1.016307 z" />
                <path
                    className={styles.YoutubePrimary}
                    d="m 4.9651953,6.5756972 10e-4,-2.053038 1.5879041,1.036731 -1.5889801,1.016307 z" />
            </g>
        </svg>

    );
}

export default YoutubeGlyph;