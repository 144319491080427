import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const TwitterGlyph = ({ height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Twitter"
            version="1.1"
            viewBox="0 0 41 41"
            height={height}
            className={styles.TwitterGlyph}>
            <title>Twitter</title>
            <g>
                <path
                    className={styles.TwitterPrimary}
                    d="M 41,20.5 A 20.5,20.5 0 0 1 20.5,41 20.5,20.5 0 0 1 0,20.5 20.5,20.5 0 0 1 20.5,0 20.5,20.5 0 0 1 41,20.5" />
                <path
                    className={styles.TwitterSecondary}
                    d="m 34.399894,12.022259 c -1.001919,0.443964 -2.078233,0.74394 -3.209742,0.879529 1.154307,-0.691145 2.039836,-1.787856 2.457403,-3.0933514 -1.079914,0.6407484 -2.276217,1.1063114 -3.549315,1.3558914 -1.019918,-1.084713 -2.471801,-1.7638586 -4.079672,-1.7638586 -3.086152,0 -5.58915,2.5029986 -5.58915,5.5915506 0,0.436765 0.0504,0.861531 0.143988,1.271898 -4.647226,-0.233981 -8.764095,-2.459803 -11.5214736,-5.841131 -0.4799614,0.827934 -0.7559392,1.787856 -0.7559392,2.810174 0,1.939045 0.9875206,3.651307 2.4861998,4.653226 -0.916726,-0.03 -1.7782567,-0.280777 -2.5317961,-0.699543 v 0.07199 c 0,2.708182 1.9258451,4.9676 4.4840391,5.481159 -0.470362,0.12719 -0.963522,0.194384 -1.472281,0.194384 -0.359971,0 -0.711543,-0.0336 -1.052316,-0.09839 0.711543,2.219822 2.775377,3.837292 5.221981,3.880488 -1.913847,1.49988 -4.324453,2.393808 -6.9426423,2.393808 -0.4511637,0 -0.8963279,-0.0264 -1.3342927,-0.07799 2.474201,1.587472 5.411565,2.511398 8.567311,2.511398 10.283173,0 15.904721,-8.516915 15.904721,-15.903521 0,-0.239981 -0.006,-0.482361 -0.0168,-0.722342 1.091913,-0.789537 2.039836,-1.772258 2.787376,-2.891768 z" />
            </g>
        </svg>
    );
}

export default TwitterGlyph;