import * as React from 'react';
import styles from './SocialGlyphs.module.scss';

const FacebookGlyph = ({ height }: { [key: string]: number }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Facebook"
            version="1.1"
            stroke="currentColor"
            viewBox="0 0 42 42"
            height={height}
            className={styles.FacebookGlyph}
        >
            <title>Facebook</title>
            <g transform="scale(3.871711)">
                <path
                    className={styles.FacebookPrimary}
                    d="M 10.847917,5.4239583 A 5.4239583,5.4239583 0 0 1 5.4239583,10.847917 5.4239583,5.4239583 0 0 1 0,5.4239583 5.4239583,5.4239583 0 0 1 5.4239583,0 5.4239583,5.4239583 0 0 1 10.847917,5.4239583" />
                <path
                    className={styles.FacebookSecondary}
                    d="M 5.72072,3.5796996 C 5.7642181,2.8356816 5.9435495,2.1698603 6.9714406,1.992612 L 8.7455383,1.95401 v 1.5752193 l -1.031371,-0.01194 C 7.4489987,3.5409297 7.3647359,3.6253796 7.3587902,3.851607 v 1.0081666 h 1.3381546 l 4e-7,1.5519429 H 7.3587902 V 9.9347876 H 5.7207023 V 6.4117117 H 4.4859508 V 4.8597544 l 1.2347692,2.4e-5 z" />
            </g>
        </svg>

    );
}

export default FacebookGlyph;