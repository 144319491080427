import * as React from 'react';
import ShoppingCart from '../ShoppingCart/ShoppingCart';
import About from './About';
import ContactUs from './ContactUs';
import styles from './Content.module.scss';
import Home from './Home';
import Products from './Products';
import Store from './Store';

export interface IContentProps {
    contentType: string;

}

export interface IContentState {

}


export default class Content extends React.Component<IContentProps, IContentState> {
    constructor(props: Readonly<IContentProps>) {
        super(props);

    }

    public render = () => {
        return (
            <div className={styles.Content} >
                <div className={styles.Container}>
                    {this.props.contentType === "home" && (<Home />)}
                    {this.props.contentType === "about" && (<About />)}
                    {this.props.contentType === "products" && (<Products />)}
                    {this.props.contentType === "store" && (<Store />)}
                    {this.props.contentType === "contactus" && (<ContactUs />)}
                    {this.props.contentType === "shoppingCart" && (<ShoppingCart />)}
                </div>
            </div>
        );
    }
}