export interface IApplicationStrings {
  CanadianMade: string;
  ProductDescription: string;
  NavHome: string;
  NavAbout: string;
  NavProducts: string;
  NavContactUs: string;
  Title: string;
  Copyright: string;
  ContactUsTitle: string;
  ContactUsInfo: string;
  Phone: string;
  Email: string;
  YourName: string;
  Message: string;
  SendMessage: string;
  FormSuccessMessage: string;
  FormErrorMessage: string;
  FormInvalidMessage: string;
  NameRequiredMessage: string;
  PhoneRequiredMessage: string;
  EmailRequiredMessage: string;
  MessageRequiredMessage: string;
  ContactPhoneNumber: string;
  ContactEmail: string;

  ShoppingCartTitle: string;
  ShoppingCartSubmit: string;

  UrlFacebook: string;
  UrlTwitter: string;
  UrlLinkedIn: string;
  UrlYoutube: string;
  UrlInstagram: string;

}

export class ApplicationStrings {
  public static Strings: IApplicationStrings = {
    CanadianMade: "Canadian Made",
    ProductDescription: "Business management services",
    NavAbout: "About",
    NavContactUs: "Contact Us",
    NavHome: "Home",
    NavProducts: "Products",
    Title: "Copihue Canada",
    Copyright: "Copyright © 2021 Copihue Canada Corp.",
    ContactUsTitle: "Contact Us",
    ContactUsInfo: "Don't hesitate to contact us",
    Phone: "Phone",
    Email: "Email",
    YourName: "Your Name",
    Message: "Message",
    SendMessage: "Send Message",
    FormSuccessMessage: "The form was successfully submitted!",
    FormErrorMessage: "Sorry, an unexpected error has occurred",
    FormInvalidMessage: "Sorry, the form is invalid. Please review, adjust and try again",
    NameRequiredMessage: "Please provide a name",
    PhoneRequiredMessage: "Phone number is required",
    EmailRequiredMessage: "Email address is required",
    MessageRequiredMessage: "Message is required",
    ContactPhoneNumber: "+1 (289) 470-0655",
    ContactEmail: "info@copihue.ca",

    ShoppingCartTitle: "Shopping Cart",
    ShoppingCartSubmit: "Submit Order",

    UrlFacebook: "https://www.facebook.com/copihueppe",
    UrlTwitter: "https://twitter.com/copihue2020",
    UrlLinkedIn: "",
    UrlInstagram: "https://www.instagram.com/copihue2020",
    UrlYoutube: ""

  }  
}
